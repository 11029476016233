'use strict';
angular.module('lmsApp').controller('TabsDemoCtrl', function ($scope, UserGroup, Classes, Region, Territory, SchoolNew, Locations, Learningclass,
                                                              Upload, $timeout) {
    $scope.examples = [{
        login: 't.balashova',
        currentClass: '1А',
        nextClass: '2А'
    }, {
        login: 'm.berezova',
        currentClass: '1А',
        nextClass: '2Б'
    }, {
        login: 'v.bochkova', currentClass: '1A', nextClass: '1Б'
    }, {
        login: 'd.bychk',
        currentClass: '11А',
        nextClass: 'Выпуск'
    }];

    $scope.listFile = null;
    $scope.locations = {
        regions: [],
        territories: [],
        schools: []
    };
    $scope.selectedLocation = {
        region: null,
        territory: null,
        school: null
    };
    init();
    $scope.onRegionChange = function () {
        if ($scope.selectedLocation.region) {
            $scope.locations.territories = Locations.children({
                id: $scope.selectedLocation.region.id
            });
        }
        $scope.selectedLocation.territory = null;
        $scope.selectedLocation.school = null;
    };

    $scope.onTerritoryChange = function () {
        if ($scope.selectedLocation.territory) {
            $scope.locations.schools = SchoolNew.getByTerritory({
                id: $scope.selectedLocation.territory.id
            });
        }
        $scope.selectedLocation.school = null;
    };


    function init() {
        Locations.query({
            type: 'REGION'
        }, function (regions) {
            $scope.locations.regions = regions;
        });

        Learningclass.getYears(function (years) {
            $scope.years = years;
            var curYear = years.filter(function (obj) {
                return obj.current;
            })[0];
            $scope.selectedCurrentYear = curYear;
            $scope.selectedNextYear = years.filter(function (obj) {
                return obj.value === curYear.value + 1;
            })[0];
        });
    }


    $scope.uploadList = function (file) {
        $scope.logs = null;
        $scope.logUrl = null;
        $scope.transferringInProgress = false;
        $scope.errorMessage = null;
        file.upload = Upload.upload({
//            url: '/api/userGroups/transferStudents',
            url: '/admin/transfer-groups',
            file: file,
            data: {
                currentYear: $scope.selectedCurrentYear.value,
                nextYear: $scope.selectedNextYear.value,
                schoolId: $scope.selectedLocation.school.id
            }
        });

        file.upload
            .progress(function () {
                $scope.transferringInProgress = true;
            })
            .success(function (data) {
                $scope.logs = [];
                $scope.transferringInProgress = false;
                var content = "Журнал для перевода воспитанников школы " +
                    $scope.selectedLocation.school.name + " " +
                    $scope.selectedCurrentYear.value + "->" + $scope.selectedNextYear.value + "\n";
                content += $scope.parseResponse(data);
                var blob = new Blob([content], {type: 'text/plain'});
                $scope.logFileName = "log-" + new Date().toJSON() + ".txt";
                $scope.logUrl = (window.URL || window.webkitURL).createObjectURL(blob);
            })
            .error(function (data) {
                $scope.errorMessage = data;
                $scope.transferringInProgress = false;
            });
    };


    $scope.clear = function () {
        $scope.listFile = null;
        $scope.selectedLocation = {
            region: null,
            territory: null,
            school: null
        };
        $scope.selectedCurrentYear = null;
        $scope.selectedNextYear = null;
        $scope.logUrl = null;
        $scope.logs = null;
        $scope.errorMessage = data;
        $scope.transferringInProgress = false;

    };

    $scope.parseResponse = function (data) {
        var result = "";
        result += "Созданные учебные классы:\n";
        result += data.createdClasses.join('\n');
        result += "\n=============================================================================================\n";
        $scope.logs.push({name: "Созданные учебные классы", count: data.createdClasses.length});

        result += "Созданные группы пользователей:\n";
        result += data.createdUserGroups.join('\n');
        result += "\n=============================================================================================\n";
        $scope.logs.push({
            name: "Созданные группы пользователей",
            count: data.createdUserGroups.length
        });

        result += "Переведенные воспитанники:\n";
        result += data.transferredStudents.join('\n');
        result += "\n=============================================================================================\n";
        $scope.logs.push({name: "Переведенные воспитанники", count: data.transferredStudents.length});

        result += "воспитанники, деактивированные в процессе загрузки в связи с выпуском:\n"
        result += data.graduatedStudents.join('\n');
        result += "\n=============================================================================================\n";
        $scope.logs.push({
            name: "воспитанники, деактивированные в процессе загрузки в связи с выпуском",
            count: data.graduatedStudents.length
        });

        result += "воспитанники, не переведенные в следующий класс по причине деактивации учетной записи воспитанника:\n";
        result += data.deactivatedStudents.join('\n');
        result += "\n=============================================================================================\n";
        $scope.logs.push({
            name: "воспитанники, деактивированные в процессе загрузки в связи с выпуском",
            count: data.graduatedStudents.length
        });

        result += "Не найдено воспитанников:\n";
        result += data.notFoundStudents.join('\n');
        result += "\n=============================================================================================\n";
        $scope.logs.push({
            name: "Не найдено воспитанников",
            count: data.notFoundStudents.length
        });
        $scope.logs.push({
            name: "Всего обработано воспитанников",
            count: data.totalCount
        });
        result += "Прочие ошибки:\n";
        result += data.errors.join('\n');
        $scope.logs.push({
            name: "Прочие ошибки",
            count: data.errors.length
        });

        return result;
    };

    $scope.removeFile = function(){
        $scope.listFile = null;
        $scope.logs = null;
        $scope.logUrl = null;
        $scope.errorMessage = null;
    }


});

