(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lmsApp')
        .constant('VERSION', "1.4.1")
        .constant('GIT', {
	"message": "Merge branch 'dev' into 'main'\n\nDev\n\nSee merge request lms-mob-edu/lms-legacy/preschool-legacy-front!30",
	"commit": "9b1d88ff5e1fefab28081e46567cf1e8d3323da2",
	"branch": "Detached: 9b1d88ff5e1fefab28081e46567cf1e8d3323da2",
	"date": "2024-07-25T13:39:46.000Z"
})
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
        .constant('API', {
	"newAdmin": "/adm",
	"course": "/course"
})
;
})();
