'use strict';

angular.module('lmsApp')
    .controller('SchoolDetailController', function ($scope, $q, $stateParams, $translate, SchoolNew, Territory, Learningclass, Users) {
        var _DIST_CORDS = 'distanceEducationCoordinators',
            _TUTORS = 'tutors',
            _TEACHERS = 'teachers',
            _HEAD_TEACHERS = 'headTeachers',
            _DEPUTY_HEAD_TEACHERS = 'deputyHeadTeachers',
            _METHODOLOGISTS = 'methodologists',
            _PSYCHOLOGISTS = 'psychologists',
            _SCHOOL_ADMIN = 'admins';

        $scope.arrUsers = [_SCHOOL_ADMIN, _HEAD_TEACHERS, _DEPUTY_HEAD_TEACHERS, _DIST_CORDS,
            _METHODOLOGISTS, _PSYCHOLOGISTS, _TUTORS, _TEACHERS];

        $scope.school = {};
        $scope.schoolAdminList = {};
        $scope.distanceEducationCoordinatorsList = {};
        $scope.tutorCoordinatorsList = {};
        $scope.teacherCoordinatorsList = {};
        $scope.headTeachersList = {};
        $scope.deputyHeadTeachersList = {};
        $scope.methodologistsList = {};
        $scope.psychologistsList = {};

        $scope.currentList = {};

        $scope.listName = '';

        $scope.coordOptions = {
            'page': 1,
            'per_page': 10,
            'sorting_fields': ['id'],
            'sorting_direction': 'asc'
        };
        $scope.addbleUser = {};

        $scope.load = function (id) {
            SchoolNew.getById({id: id}, function (result) {
                $scope.school = result;
                // Id школы для добавления юзера
                $scope.addbleUser.firstId = $scope.school.id;

            });
        };

        $scope.addUserDialog = function (nameList) {
            $scope.listName = nameList;
            var promise = $scope.getPromiseSchoolStaff();
            var resultObj;
            promise.then(function(result){ resultObj = result;

            switch(nameList) {
                case _DIST_CORDS:
                    $scope.currentList = angular.copy(resultObj, $scope.distanceEducationCoordinatorsList);
                    showUserDialog()
                    break
                case _TUTORS:
                    $scope.currentList = angular.copy(resultObj, $scope.tutorCoordinatorsList);
                    showUserDialog()
                    break
                case _TEACHERS:
                    $scope.currentList = angular.copy(resultObj, $scope.teacherCoordinatorsList);
                    showUserDialog()
                    break
                case _HEAD_TEACHERS:
                    $scope.currentList = angular.copy(resultObj, $scope.headTeachersList);
                    showUserDialog()
                    break
                case _DEPUTY_HEAD_TEACHERS:
                    $scope.currentList = angular.copy(resultObj, $scope.deputyHeadTeachersList);
                    showUserDialog()
                    break
                case _METHODOLOGISTS:
                    $scope.currentList = angular.copy(resultObj, $scope.methodologistsList);
                    showUserDialog()
                case _PSYCHOLOGISTS:
                    $scope.currentList = angular.copy(resultObj, $scope.psychologistsList);
                    showUserDialog()
                    break
                case _SCHOOL_ADMIN:
                    $scope.currentList = angular.copy(resultObj, $scope.schoolAdminList);
                    showUserDialog()
                    break
                default:
                    console.log(no-role);
                    break;
            };

            })
        };
        function showUserDialog() {
            $('#addUserModal').modal('show');
        }
        $scope.showDeleteUserDialog = function (nameList) {
            $scope.listName = nameList;
            switch(nameList) {
                case _DIST_CORDS:
                    $scope.currentList = $scope.school.distanceEducationCoordinators;
                    break;
                case _TUTORS:
                    $scope.currentList = $scope.school.tutors;
                    break;
                case _TEACHERS:
                    $scope.currentList = $scope.school.teachers;
                    break;
                case _HEAD_TEACHERS:
                    $scope.currentList = $scope.school.headTeachers;
                    break;
                case _DEPUTY_HEAD_TEACHERS:
                    $scope.currentList = $scope.school.deputyHeadTeachers;
                    break;
                case _METHODOLOGISTS:
                    $scope.currentList = $scope.school.methodologists;
                    break;
                case _PSYCHOLOGISTS:
                    $scope.currentList = $scope.school.psychologists;
                    break;
                case _SCHOOL_ADMIN:
                    $scope.currentList = $scope.school.admins;
                    break;
            }
            $scope.removedUserError = false;
            $scope.removedUserErrorMsg = '';
            $('#deleteUserModal').modal('show');
        };

        $scope.addUser = function (nameList) {
            $scope.listName = nameList;
            switch (nameList) {
                case _DIST_CORDS:
                    $scope.addbleUser.secondId = $scope.newUser.id;
                    SchoolNew.addCoordinators($scope.addbleUser, function() {
                        $scope.school.distanceEducationCoordinators.push($scope.newUser);
                        closeAddUser()
                    });

                    break;
                case _TUTORS:
                    $scope.addbleUser.secondId = $scope.newUser.id;
                    SchoolNew.addTutors($scope.addbleUser, function() {
                        $scope.school.tutors.push($scope.newUser);
                        closeAddUser()
                    });

                    break;
                case _TEACHERS:
                    $scope.addbleUser.secondId = $scope.newUser.id;
                    SchoolNew.addTeachers($scope.addbleUser, function() {
                        $scope.school.teachers.push($scope.newUser);
                        closeAddUser()
                    });
                    break;
                case _HEAD_TEACHERS:
                    $scope.addbleUser.secondId = $scope.newUser.id;
                    SchoolNew.addHeadteachers($scope.addbleUser, function() {
                        $scope.school.headTeachers.push($scope.newUser);
                        closeAddUser()
                    });
                    break;
                case _DEPUTY_HEAD_TEACHERS:
                    $scope.addbleUser.secondId = $scope.newUser.id;
                    SchoolNew.addDeputyHeadteachers($scope.addbleUser, function() {
                        $scope.school.deputyHeadTeachers.push($scope.newUser);
                        closeAddUser()
                    });
                    break;
                case _METHODOLOGISTS:
                    $scope.addbleUser.secondId = $scope.newUser.id;
                    SchoolNew.addMethodologists($scope.addbleUser, function() {
                        $scope.school.methodologists.push($scope.newUser);
                        closeAddUser()
                    });
                    break;
                case _PSYCHOLOGISTS:
                    $scope.addbleUser.secondId = $scope.newUser.id;
                    SchoolNew.addPsychologists($scope.addbleUser, function() {
                        $scope.school.psychologists.push($scope.newUser);
                        closeAddUser()
                    });
                    break;
                case _SCHOOL_ADMIN:
                    $scope.addbleUser.secondId = $scope.newUser.id;
                    SchoolNew.addAdmins($scope.addbleUser, function() {
                        $scope.school.admins.push($scope.newUser);
                        closeAddUser()
                    });
                    break;
                default:
                    return false;
            }

        };

        $scope.deleteUser = function (nameList) {
            $scope.listName = nameList;
            var delUser = {
                id: $scope.school.id,
            }
            switch (nameList) {
                case _DIST_CORDS:
                    delUser.coordinator_id =  $scope.removedUserId;
                    SchoolNew.deleteCoordinators(delUser, function(){
                        $('#deleteUserModal').modal('hide');
                            closeDelUser()
                    })
                    break;
                case _TUTORS:
                    delUser.tutor_id = $scope.removedUserId;
                    SchoolNew.deleteTutors(delUser, function(){
                        $('#deleteUserModal').modal('hide');
                            closeDelUser()
                    })
                    break;
                case _TEACHERS:
                    delUser.teacher_id =  $scope.removedUserId;
                    SchoolNew.deleteTeachers(delUser, function(){
                        $('#deleteUserModal').modal('hide');
                        closeDelUser();
                    }, function(res) {
                        var headers = res.headers();
                        var msg = 'Error!';
                        $scope.removedUserError = true;
                        if (+headers.error_code === 3500) {
                            $translate('lmsApp.school.errors.code3500').then(function (translatedValue) {
                                $scope.removedUserErrorMsg = translatedValue;
                            });
                        } else {
                            $scope.removedUserErrorMsg = msg;
                        }
                    });
                    break;
                case _HEAD_TEACHERS:
                    delUser.head_teacher_id =  $scope.removedUserId;
                    SchoolNew.deleteHeadteachers(delUser, function(){
                        $('#deleteUserModal').modal('hide');
                            closeDelUser()
                    })
                    break;
                case _DEPUTY_HEAD_TEACHERS:
                    delUser.deputy_head_teacher_id = $scope.removedUserId
                    SchoolNew.deleteDeputyHeadteachers(delUser, function(){
                        $('#deleteUserModal').modal('hide');
                            closeDelUser()
                    })
                    break;
                case _METHODOLOGISTS:
                    delUser.methodologist_id = $scope.removedUserId
                    SchoolNew.deleteMethodologists(delUser, function(){
                        $('#deleteUserModal').modal('hide');
                            closeDelUser()
                    })
                    break;
                case _PSYCHOLOGISTS:
                    delUser.psychologist_id = $scope.removedUserId
                    SchoolNew.deletePsychologists(delUser, function(){
                        $('#deleteUserModal').modal('hide');
                            closeDelUser()
                    })
                    break;
                case _SCHOOL_ADMIN:
                    delUser.admin_id = $scope.removedUserId;
                    SchoolNew.deleteAdmins(delUser, function(){
                        $('#deleteUserModal').modal('hide');
                            closeDelUser()
                    })
                    break;
                default:
                    return false;
            }
        };

        function removeAddedCoordinatorsFromList(arrUserInTable, arrUser) {
            if (arrUserInTable.length === 0) {
                return arrUser;
            }
            var tempArr = [];
            for (var z = 0; z < arrUserInTable.length; z++) {
                tempArr = remove(arrUser, arrUserInTable[z]);
            }
            return tempArr;
        }

        function remove(arr, item) {
            for (var i = arr.length; i--;) {
                if (arr[i].id === item.id) {
                    arr.splice(i, 1);
                }
            }
            return arr;
        }

        function removeUserById(list, id) {
            id = parseInt(id, 10);
            for (var n = 0; n < list.length; n++) {
                if (list[n].id === id) {
                    list.splice(n, 1);
                    return list;
                }
            }
            return list;
        }

        function getUserByRole(role) {
            var getStaff = function(options, callback) {
                Users[role](options, callback)
            };
            return getStaff;
        }

        $scope.getPromiseSchoolStaff = function () {
            return $q(function(resolve, reject) {
                var _role, _loadOptions;

                _loadOptions = $scope.coordOptions;
                _loadOptions.schoolId = $scope.school.id;
                _role = $scope.listName;

                Users[_role](_loadOptions, function (result, headers) {
                    var resultObj = {
                        'items': result,
                        'page': 1,
                        'total': headers('X-Total-Count')
                    };

                resolve(resultObj);
                },function(error){
                    reject(error)
                });
            })
        };
        $scope.getSchoolStaff = function (loadOptions) {
            var _role, _loadOptions;
            _loadOptions = loadOptions;
            _loadOptions.schoolId = $scope.school.id;
            _role = $scope.listName;

            Users[_role](_loadOptions, function (result, headers) {
                var resultObj = {
                    'items': result,
                    'total': headers('X-Total-Count')
                };
                switch(_role) {
                    case _DIST_CORDS:
                        angular.copy(resultObj, $scope.distanceEducationCoordinatorsList);
                        break
                    case _TUTORS:
                        angular.copy(resultObj, $scope.tutorCoordinatorsList);
                        break
                    case _TEACHERS:
                        angular.copy(resultObj, $scope.teacherCoordinatorsList);
                        break
                    case _HEAD_TEACHERS:
                        angular.copy(resultObj, $scope.headTeachersList);
                        break
                    case _DEPUTY_HEAD_TEACHERS:
                        angular.copy(resultObj, $scope.deputyHeadTeachersList);
                        break
                    case _METHODOLOGISTS:
                        angular.copy(resultObj, $scope.methodologistsList);
                    case _PSYCHOLOGISTS:
                        angular.copy(resultObj, $scope.psychologistsList);
                        break
                    case _SCHOOL_ADMIN:
                        angular.copy(resultObj, $scope.schoolAdminList);
                        break
                    default:
                        new Error('no-role');
                        break;
                };
            })
        }

        function closeAddUser() {
            $('#addUserModal').modal('hide');
            $scope.newUser = void 0;
            $scope.load($stateParams.id);
        }
        function closeDelUser() {
            $('#deleteUserModal').modal('hide');
            $scope.load($stateParams.id);
        }

        $scope.load($stateParams.id);
    });
