'use strict';

angular.module('lmsApp').controller('UserImportController', [
  '$scope',
  'Territory',
  'Locations',
  'School',
  'SchoolNew',
  'Learningclass',
  'UserGroup',
  'User',
  'Course',
  'CourseSection',
  'Cruduser',
  function (
    $scope,
    Territory,
    Locations,
    School,
    SchoolNew,
    Learningclass,
    UserGroup,
    User,
    Course,
    CourseSection,
    Cruduser
  ) {
    var courseSections = [],
      userRoles = {
        teacher: {
          name: 'Воспитатель',
          value: 'ROLE_TEACHER'
        },
        student: {
          name: 'Воспитанник',
          value: 'ROLE_STUDENT'
        }
      };
    $scope.regions = [];
    $scope.territories = [];
    $scope.schools = [];
    $scope.openedTab = 1;
    $scope.importData = {
      selectedRegion: null,
      selectedTerritory: null,
      selectedSchool: null,
      usersDataString: null
    };
    $scope.userSCategories = []
    $scope.parsedUsers = [];
    $scope.notValidatedUsers = [];
    $scope.parsedClasses = [];
    $scope.parsedCourses = [];
    $scope.parsedUserGroups = {};
    $scope.hasErrors = false;
    $scope.counters = {
      userGroups: 0,
      learningClasses: 0,
      courseSections: 0,
      users: 0
    };
    $scope.init = function () {
      Locations.query(
        {
          type: 'REGION'
        },
        function (regions) {
          // Сортируем
          var regionsSorted = regions.sort(function (itemA, itemB) {
            if (itemA.name < itemB.name) {
              return -1;
            }
            return 1;
          });
          // ---
          $scope.regions = regionsSorted;
          Learningclass.getYears(function (years) {
            // Всё что раньше 2019 убираем
            var yearsFiltred = years.filter(function (item) {
              if (item.value >= 2019) {
                return true;
              }
              return false;
            });
            $scope.years = yearsFiltred;
          });
        }
      );
    };
    $scope.init();
    $scope.onRegionChange = function () {
      if (!!$scope.importData.selectedRegion) {
        Locations.children({ id: $scope.importData.selectedRegion.id }, function (result) {
          // Сортируем
          var territoriesSorted = result.sort(function (itemA, itemB) {
            if (itemA.name < itemB.name) {
              return -1;
            }
            return 1;
          });
          // ---
          $scope.territories = territoriesSorted;
        });
      }
      $scope.importData.selectedTerritory = null;
      $scope.importData.selectedSchool = null;
    };
    $scope.onTerritoryChange = function () {
      if (!!$scope.importData.selectedTerritory) {
        var schools = School.getByTerritory({
          id: $scope.importData.selectedTerritory.id
        });
        // Сортируем
        var schoolsSorted = schools.sort(function (itemA, itemB) {
          if (itemA.name < itemB.name) {
            return -1;
          }
          return 1;
        });
        // ---
        $scope.schools = schoolsSorted;
      }
      $scope.importData.selectedSchool = null;
    };
    $scope.showConfirm = function () {
      $('#newConfirmation').modal('show');
    };
    $scope.openStepOne = function (newImport) {
      if (newImport) {
        clearImportData();
        $('#newConfirmation').modal('hide');
      }
      clearParsedData();
      $scope.openedTab = 1;
    };
    $scope.openStepTwo = function () {
      var rows = $scope.importData.usersDataString.split('\n'), courses, cells, lc, i;
      clearParsedData();
      for (i = 0; i < rows.length; i++) {
        cells = rows[i].split('\t');
        if (cells.length >= 7) {
          courses = addCourses(cells);
          lc = addLearningClass(cells, courses);
          addUser(cells, i, lc, courses);
        } else {
          //TODO: Handle wrong data error
        }
      }
      if ($scope.parsedUsers.length > 0 && $scope.notValidatedUsers.length === 0) {
        getLearningClassesForStepTwo();
      } else {
        $scope.hasErrors = true;
      }
    };
    $scope.skipToStepTwo = function () {
      $scope.notValidatedUsers = [];
      $scope.hasErrors = false;
      getLearningClassesForStepTwo();
    };
    $scope.openStepThree = function () {
      //Create userGroups and LearningClasses
      $scope.parsedClasses.forEach(function (learningClass) {
        if (!learningClass.userGroup) {
          var tmpLearningClass = learningClass;
          tmpLearningClass.learningClasses = []; // delete tmpLearningClass.courses;
          var userGroupLearningClass = _.cloneDeep(tmpLearningClass);
          tmpLearningClass.learningClasses.push(userGroupLearningClass);
          createUserGroupe(tmpLearningClass);
        }
      });
      $scope.searchForCourses();
      $scope.openedTab = 3;
    };
    function createUserGroupe(learningClass) {
      if (!learningClass.id) {
        var newClass = {
          letter: learningClass.letter,
          name: learningClass.name,
          parallel: learningClass.parallel,
          school: learningClass.school,
          year: learningClass.year
        };
        Learningclass.update(newClass, function (response, userGroupHeaders) {
          var group = {
            learningClassId: response.id,
            name: response.name,
            tutorId: response.tutor ? response.tutor.id : null
          };
          UserGroup.create(group, function (response) {
            learningClass.userGroup = response;
            $scope.parsedUserGroups[learningClass.userGroup.id] = learningClass.userGroup;
          }); // if (learningClass.userGroup.learningClasses[0].id) { //     Learningclass.update(learningClass); // } // else { //     var LearnC = _.cloneDeep(learningClass); //     delete LearnC.learningClasses; //     Learningclass.create(LearnC, function (lClassResponse, lClassHeaders) { //         $scope.counters.learningClasses += 1; //         learningClass.id = lClassHeaders('Location').match(/[0-9]+/)[0];
          //     });
          // }
        });
      } else {
        var group = {
          learningClassId: learningClass.id,
          name: learningClass.name // id: learningClass.userGroup ? learningClass.userGroup.id : null,
        };
        UserGroup.update(group, function (response) {
          learningClass.userGroup = response;
          $scope.parsedUserGroups[learningClass.userGroup.id] = learningClass.userGroup;
        });
      }
    }
    $scope.openStepFour = function () {
      //Create course sections
      $scope.parsedClasses.forEach(function (learningClass) {
        learningClass.courses.forEach(function (course) {
          if (course.id && course.published) {
            CourseSection.getByCourseIdAndUserGroupId(
              {
                courseId: course.id,
                groupId: learningClass.userGroup.id
              },
              function (foundCourseSection) {
                foundCourseSection.learningClassLetter = learningClass.letter;
                courseSections.push(foundCourseSection);
              },
              function (error) {
                if (error.status === 404) {
                  CourseSection.update(
                    {
                      name: course.name + ' ' + learningClass.parallel + learningClass.letter,
                      userGroup: {
                        id: learningClass.userGroup.id
                      },
                      course: {
                        id: course.id
                      }
                    },
                    function (response, headers) {
                      CourseSection.get(
                        {
                          id: headers('Location').match(/[0-9]+/)[0]
                        },
                        function (newCourseSection) {
                          newCourseSection.learningClassLetter = learningClass.letter;
                          courseSections.push(newCourseSection);
                        }
                      );
                      $scope.counters.courseSections += 1;
                    }
                  );
                }
              }
            );
          }
        });
      });
      $scope.openedTab = 4;
    };
    $scope.openStepFive = function () {
      var hasNewTeachers = false, id;
      Cruduser.batchCreate($scope.parsedUsers, function (response) {
        $scope.counters.users += response.length;
        response.forEach(function (user) {
          var localUser = $scope.parsedUsers[user.index];
          localUser.id = user.id;
          if (user.newLogin) {
            localUser.login = user.newLogin;
          }
          if (localUser.userRoles[0] === userRoles.student.value) {
            if ($scope.parsedUserGroups[localUser.learningClass.userGroup.id]) {
              $scope.parsedUserGroups[localUser.learningClass.userGroup.id].users.push(localUser);
            } else {
              $scope.parsedUserGroups[localUser.learningClass.userGroup.id] = {
                users: []
              };
              $scope.parsedUserGroups[localUser.learningClass.userGroup.id].users.push(localUser);
            }
          } else if (localUser.userRoles[0] === userRoles.teacher.value) {
            hasNewTeachers = true;
            $scope.importData.selectedSchool.teachers = $scope.importData.selectedSchool.teachers
              ? $scope.importData.selectedSchool.teachers
              : [];
            $scope.importData.selectedSchool.teachers.push(localUser);
            localUser.possibleCourses = [];
            localUser.courses.forEach(function (course) {
              if (course.id) {
                localUser.possibleCourses.push(course);
              }
            });
            if (localUser.possibleCourses.length > 0) {
              User.updateCourses(
                {
                  id: localUser.id,
                  possibleCourses: localUser.possibleCourses
                },
                function () {
                  localUser.possibleCourses.forEach(function (course) {
                    courseSections.some(function (cs) {
                      if (
                        course.name === cs.course.name &&
                        course.parallel === cs.course.parallel &&
                        localUser.learningClass.letter === cs.learningClassLetter
                      ) {
                        cs.user = localUser;
                        CourseSection.update(cs);
                        return true;
                      }
                    });
                  });
                }
              );
            }
            if (hasNewTeachers) {
              var teacher = {
                firstId: $scope.importData.selectedSchool.id,
                secondId: localUser.id
              };
              SchoolNew.addTeachers(teacher);
            }
          }
        });
        for (id in $scope.parsedUserGroups) {
          var allUserIds = [], userAdd = {};
          if ($scope.parsedUserGroups.hasOwnProperty(id)) {
            allUserIds = $scope.parsedUserGroups[id].users[
              0
            ].learningClass.userGroup.users.map(function (user) {
              return user.id;
            });
            $scope.parsedUserGroups[id].users.forEach(function (currentUser) {
              currentUser.learningClass.userGroup.users = currentUser.learningClass.userGroup.users
                ? currentUser.learningClass.userGroup.users
                : [];
              allUserIds.push(
                (function (currentUser) {
                  return currentUser.id;
                })(currentUser)
              );
              userAdd = {
                id: +id,
                learningClassId: currentUser.learningClass.id
                  ? currentUser.learningClass.id
                  : currentUser.learningClass.userGroup.learningClasses[0].id,
                name: currentUser.learningClass.userGroup.name,
                tutorId: currentUser.learningClass.userGroup.tutor
                  ? currentUser.learningClass.userGroup.tutor.id
                  : null,
                userIds: allUserIds
              };
            });
            UserGroup.update(userAdd);
          }
        }
        $scope.openedTab = 5;
      });
    };
    $scope.searchForCourses = function () {
      $scope.parsedCourses.forEach(function (course) {
        course.id = null;
        Course.query(
          {
            search_text: course.name
          },
          function (foundCourses) {
            foundCourses.some(function (foundCourse) {
              if (
                foundCourse.name.toLowerCase() === course.name.toLowerCase()
              ) {
                course.id = foundCourse.id;
                course.published = foundCourse.published;
                return true;
              }
            });
          }
        );
      });
    };
    function getLearningClassesForStepTwo() {
      Learningclass.getBySchool(
        {
          id: $scope.importData.selectedSchool.id
        },
        function (result) {
          $scope.parsedClasses.forEach(function (newClass) {
            result.some(function (existingClass) {
              if (
                newClass.parallel === existingClass.parallel &&
                newClass.letter.toLowerCase() === existingClass.letter.toLowerCase() &&
                newClass.year === existingClass.year.value
              ) {
                newClass.id = existingClass.id;
                newClass.name = existingClass.name;
                newClass.userGroup = existingClass.userGroup ? existingClass.userGroup : null;
                delete existingClass.userGroup;
                if (existingClass.userGroup) {
                  $scope.parsedUserGroups[newClass.userGroup.id] = newClass.userGroup;
                  $scope.parsedUserGroups[newClass.userGroup.id].learningClasses = [];
                  $scope.parsedUserGroups[newClass.userGroup.id].learningClasses.push(
                    existingClass
                  );
                  return true;
                }
              }
            });
          });
          $scope.openedTab = 2;
        }
      );
    }
    function addUser(cells, i, learningClass, courses) {
      var user = {
        index: i,
        lastName: cells[0].trim(),
        firstName: cells[1].trim(),
        middleName: cells[2].trim(),
        email: cells[3].trim(),
        adaptiveProgram: cells[5].trim(),
        password: Math.random().toString(36).slice(-6),
        userRoles: [],
        learningClass: learningClass,
        school: learningClass.school,
        courses: courses
      },
        role = cells[4].trim().toLowerCase(),
        emailRegExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      user.login = transliterate(user.firstName.charAt(0).toLowerCase()) +
        '.' +
        transliterate(user.lastName.toLowerCase());
      user.s_category = getSCategoryByNumber(user.adaptiveProgram)
      user.reason = '';
      if (user.learningClass.notValidated) {
        user.reason = 'Неправильно указан учебный класс';
      }
      if (!emailRegExp.test(user.email)) {
        if (user.reason) {
          user.reason += ', ';
        }
        user.reason += 'Неправильно указан email';
      }
      if (!user.s_category) {
        if (user.reason) {
          user.reason += ', ';
        }
        user.reason += 'Неправильно указана категория';
      }
      if (role === userRoles.student.name) {
        user.userRoles.push(userRoles.student.value);
      } else if (role === userRoles.teacher.name) {
        user.userRoles.push(userRoles.teacher.value);
      } else {
        user.userRoles.push(role);
        if (user.reason) {
          user.reason += ', ';
        }
        user.reason += 'Неправильная роль';
      }
      if (user.reason) {
        $scope.notValidatedUsers.push(user);
      } else {
        $scope.parsedUsers.push(user);
      }
    }
    function addLearningClass(cells, courses) {
      var className = cells[6].trim(),
        parallel = parseInt(className, 10), //вырезать параллель, обрезать пробелы и найти все буквы
        letter = className
          .slice(className.indexOf(parallel) + 1)
          .trim()
          .replace(/[^а-яёa-z]*/gi, ''),
        learningClass = {
          name: parallel + letter,
          parallel: parallel,
          letter: letter,
          school: {
            id: $scope.importData.selectedSchool.id
          },
          year: $scope.importData.year.value,
          courses: courses
        },
        savedLearningClass;
      if (
        (isNaN(learningClass.parallel) && !isFinite(learningClass.parallel)) ||
        learningClass.letter.length < 1
      ) {
        learningClass.notValidated = true;
        return learningClass;
      }
      savedLearningClass = containsClass(learningClass, $scope.parsedClasses);
      if (savedLearningClass) {
        //Добавляем новые курсы в класс и удаляем дубликаты
        savedLearningClass.courses = arrayUnique(savedLearningClass.courses.concat(courses));
        return savedLearningClass;
      } else {
        $scope.parsedClasses.push(learningClass);
        return learningClass;
      }
    }
    function addCourses(cells) {
      var re = /\s*,\s*|\s*;\s*/,
        className = cells[6].trim(),
        parallel = parseInt(className, 10),
        courseNames = cells[7].split(re),
        result = [];
      if (isNaN(parallel) && !isFinite(parallel)) {
        return result;
      }
      courseNames.forEach(function (courseName) {
        var course = {
          name: courseName.trim(),
          parallel: parallel
        },
          savedCourse = containsCourse(course, $scope.parsedCourses);
        if (savedCourse) {
          result.push(savedCourse);
        } else {
          $scope.parsedCourses.push(course);
          result.push(course);
        }
      });
      return result;
    }
    function clearImportData() {
      $scope.importData = {
        selectedRegion: null,
        selectedTerritory: null,
        selectedSchool: null,
        usersDataString: null
      };
    }
    function clearParsedData() {
      courseSections = [];
      $scope.parsedUsers = [];
      $scope.notValidatedUsers = [];
      $scope.parsedClasses = [];
      $scope.parsedCourses = [];
      $scope.parsedUserGroups = {};
      $scope.counters = {
        userGroups: 0,
        learningClasses: 0,
        courseSections: 0,
        users: 0
      };
    }
    function arrayUnique(array) {
      for (var i = 0; i < array.length; ++i) {
        for (var j = i + 1; j < array.length; ++j) {
          if (array[i] === array[j]) {
            array.splice(j--, 1);
          }
        }
      }
      return array;
    }
    function containsClass(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i].name.toLowerCase() === obj.name.toLowerCase()) {
          return list[i];
        }
      }
      return false;
    }
    function containsCourse(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (
          list[i].name.toLowerCase() === obj.name.toLowerCase() && list[i].parallel === obj.parallel
        ) {
          return list[i];
        }
      }
      return false;
    }
    function transliterate(word) {
      var answer = '', a = {}, i;
      a['ё'] = 'yo';
      a['й'] = 'i';
      a['ц'] = 'ts';
      a['у'] = 'u';
      a['к'] = 'k';
      a['е'] = 'e';
      a['н'] = 'n';
      a['г'] = 'g';
      a['ш'] = 'sh';
      a['щ'] = 'sch';
      a['з'] = 'z';
      a['х'] = 'h';
      a['ъ'] = '';
      a['ф'] = 'f';
      a['ы'] = 'i';
      a['в'] = 'v';
      a['а'] = 'a';
      a['п'] = 'p';
      a['р'] = 'r';
      a['о'] = 'o';
      a['л'] = 'l';
      a['д'] = 'd';
      a['ж'] = 'zh';
      a['э'] = 'e';
      a['я'] = 'ya';
      a['ч'] = 'ch';
      a['с'] = 's';
      a['м'] = 'm';
      a['и'] = 'i';
      a['т'] = 't';
      a['ь'] = '';
      a['б'] = 'b';
      a['ю'] = 'yu';
      for (i in word) {
        if (word.hasOwnProperty(i)) {
          if (a[word[i]] === undefined) {
            answer += word[i];
          } else {
            answer += a[word[i]];
          }
        }
      }
      return answer;
    }
    function getSCategoryByNumber(number) {
      var categories = ['C_DEFAULT', 'C_AQUA', 'C_BLUE', 'C_YELLOW', 'C_ORANGE', 'C_RED', 'C_GREEN', 'C_PURPLE', 'C_GREY']
      if (number < 0 || number > categories.length - 1)
        return null;
      return categories[number]
    }
  }
]);
